<template>
  <div class="gift-login">
    <div class="gift-login-container">
      <img
        class="gift-login-logo"
        src="../assets/icon/giftLogin/logo.png"
      />
      <p class="app-tip">输入手机号领取藏品</p>
      <van-form
        validate-first
        @submit="login"
      >
        <div class="login-phone">
          <van-field
            autocomplete="off"
            v-model="phone"
            maxlength="11"
            ref="phone"
            type="tel"
            name="validatorPhone"
            placeholder="请输入您的手机号码"
            :rules="[{ validator:validatorPhone, message: '请输入正确的手机号码' }]"
          />
        </div>
        <div
          v-if="isCaptcha"
          @click="getSms"
          class="right-slider-container"
          style="margin: 12px 22px;"
        >
          <p class="right-slider-p">滑块验证</p>
          <div
            id="login-main-right-slider"
            class="login-main-right-slider"
          >
            <!-- 滑块验证 -->
          </div>
        </div>

        <div
          v-if="!isCaptcha"
          class="sms-container"
        >
          <van-field
            autocomplete="off"
            v-model="sms"
            ref="sms"
            class="login-sms"
            maxlength="6"
            type="digit"
            name="validatorSms"
            placeholder="请输入短信验证码"
            :rules="[{validator:validatorSms, message: '请输入正确的短信验证码' }]"
          />
          <button
            type="button"
            class="sms-button"
            :disabled="disabled"
            @click="getSms"
          >
            {{ text }}
          </button>
        </div>
        <!-- 邀请码 -->
        <div class="login-invite">
          <van-field
            autocomplete="off"
            v-model="inviteCode"
            maxlength="12"
            type="text"
            name="validatorPhone"
            placeholder="领取码"
          />
        </div>
        <div
          @click="login"
          class="gift-login-button"
        >
          <img
            class="gift-login-bg"
            native-type="submit"
            src="../assets/icon/giftLogin/button-bg.png"
          />
          <img
            class="gift-login-tip"
            src="../assets/icon/giftLogin/button-tip.png"
          />
        </div>

      </van-form>
      <div
        style="margin-top: 100px;"
        class="user-agreement"
      >
        <p class="user-agreement-title">智链2.0提供区块链技术支持</p>
        <p class="user-agreement-tip">Copyright © GenimousChain Ltd.All rights reserved</p>
      </div>
    </div>
  </div>

</template>
<script>
import { create } from '../lib/captcha.js'
import { captchaAppKey } from '../lib/index.js'
import api from '../api/index-client'
import Cookies from 'js-cookie'
import md5 from 'js-md5';
const config = require('../config')
import Vue from 'vue';
import { Form,Field,Button,Dialog } from 'vant';

Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Dialog);

export default {
  data () {
    return {
      isCaptcha: false,
      inviteCode: '',
      show: false,
      time: null,
      phone: '',
      value2: '',
      sms: '',
      disabled: false,
      text: '获取验证码',
      title: '',
      loginParam: {
        sessionId: '',
        sign: '',
        token: '',
        scene: '',
        username: '',
        password: ''
      }
    }
  },
  watch: {
    phone () {
      if (this.phone.length >= 11 || !/^[0-9]*$/.test(this.phone)) {
        this.$refs.phone.validate()
      }
    },
    sms () {
      if (this.sms.length >= 6 || !/^[0-9]*$/.test(this.sms)) {
        this.$refs.sms.validate()
      }
    }
  },
  created () {
    this.$store.commit("HIDE_APPLOADING")
    if (this.$route.query.code) {
      this.inviteCode = this.$route.query.code
    }
  },
  methods: {
    validatorPhone (val) {
      return /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(val);
    },
    validatorSms (val) {
      return /^\d{6}$/.test(val);
    },
    login () {
      if (!this.inviteCode) {
        this.$toast({
          message: '请输入正确的领取码',
          icon: require('../assets/icon/toast-error.png'),
        });
      } else {
        if (this.sms.length != 6 || !/^[0-9]*$/.test(this.sms)) {
          this.$toast({
            message: '请输入正确的验证码',
            icon: require('../assets/icon/toast-error.png'),
          });
        } else {
          let params
          params = { "mobile": this.phone,"code": this.sms,"deviceNo": "xxxxxxx","from": "h5" }
          api
            .post('mlogin',params)
            .then(result => {

              if (result.data.success) {
                Cookies.set('Login',result.data.data.token,{ expires: 7 })
                Cookies.set('riskConfirm',true)
                this.getGiftList()
                if (localStorage.getItem('spread')) {
                  localStorage.removeItem('spread')
                }
              } else {
                this.$toast({
                  message: result.data.msg,
                  icon: require('../assets/icon/toast-error.png'),
                });
              }
            })
        }
      }
    },
    // 领取奖品
    getGiftList () {
      api
        .post('grantAssets',{ configId: this.$route.query.id,code: this.inviteCode })
        .then(result => {
          if (result.data.success) {
            this.$router.push('/giftReceive?id=' + this.$route.query.id)
          } else {
            this.time = null
            this.sms = ""
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    goAgreementPolicy (e) {
      this.$router.push('/agreementPolicy?type=' + e + '&from=h5')
    },
    getSms () {
      /**
      * 创建滑块验证模块，并重置登录所需参数
      */
      this.$refs.phone.validate().then(result => {
        if (!result) {
          this.isCaptcha = true
          this.$nextTick(() => {
            this.captcha = create('login-main-right-slider',data => {
              var timestamp = new Date().getTime()
              var nonce = md5(String(timestamp))
              var signStr = 'appId=' + config.appId + '&mobile=' + this.phone + '&nonce=' + nonce + '&timestamp=' + timestamp + '&url=' + config.server + '/api/sms&' + config.appSecret
              var sign = md5(signStr)
              api
                .post('afs/sms',{ phone: this.phone,sessionId: data.sessionId,signature: data.sign,token: data.token,scene: data.scene,appkey: captchaAppKey },{},{ 'X-Requested-With': 'XMLHttpRequest','Access-Control-Allow-Origin': '*','Access-Control-Allow-Method': 'POST,GET,DELETE,OPTIONS','Authorization': "Bearer " + Cookies.get('Login'),"appId": config.appId,"timestamp": timestamp,"nonce": nonce,"sign": sign,"url": config.server + '/api/sms' })
                .then(result => {
                  if (result.data.success) {
                    this.time = 60;
                    this.timer();
                    this.$toast.success('验证码发送成功')
                  } else {
                    this.$toast({
                      message: result.data.msg,
                      icon: require('../assets/icon/toast-error.png'),
                    });
                  }
                  this.isCaptcha = false
                })
            })
            this.captcha.reset()
            this.captcha.show()
          })

        }
      })
    },
    //发送手机验证码倒计时
    timer () {
      if (this.time > 0) {
        this.disabled = true;
        this.time--;
        this.text = this.time + "秒";
        setTimeout(this.timer,1000);
      } else {
        this.time = 0;
        this.text = "发送验证码";
        this.disabled = false;
      }
    },
  },
}
</script>
<style lang="less" scoped>
.gift-login-logo {
  margin-top: 0px;
  margin-bottom: 19px;
  width: 50px;
  height: auto;
  position: absolute;
  top: 30px;
  left: 10vw;
}
.gift-login-tip-bg {
  position: relative;
  img {
    width: 74.6vw;
    height: auto;
    margin-top: 0;
  }
  p {
    position: absolute;
    top: 10px;
    width: 70vw;
    left: 2.3vw;
    font-size: 13px;
    font-family: zcool-gdh;
    font-weight: 400;
    color: #ffffff;
  }
}
.app-tip {
  margin-top: 150px;
  margin-bottom: 30px;
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 400;
  color: #fcfbfc;
  line-height: 19px;
}
.van-number-keyboard {
  background: rgba(21, 21, 21, 0.85);
}
.log-off-tip3 {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 19px;
  width: 80%;
  margin-left: 10%;
}
/deep/.van-number-keyboard .van-key {
  background-color: #646465;
  box-shadow: 0px 1px 0px 0px #000000;
  font-family: Helvetica;
  color: #ffffff;
}
</style>